import { CardDataProps } from '@markfazzio/react-pokemon-card/types/components/Card/Card.types';
import { CARD_SETS, CARD_SETS_JPN } from '@markfazzio/pokemon-card-library';
import {
  Metafield,
  Product,
  ProductEdge
} from '@shopify/hydrogen-react/storefront-api-types';

// utils
import { goToAdminHomePage, goToCategoryPage } from '../utils/routing-utils';

// common
import { TCG_SDK_SET_IDS } from '../common/constants';
import { DefaultOptionType, SiteUser } from '../common/interfaces';

// components
import { Variant } from '../components/product/VariantsGrid/VariantsGrid.types';

export const formatPrice = (
  price: number,
  locale = 'en-US',
  currencyLocale = 'USD'
) => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyLocale
  });

  return formattedPrice.format(price);
};

export const formatCardsDataFromFauna = (
  faunaCards: Array<{ data: CardDataProps }>,
  replaceId?: boolean
): Array<CardDataProps> => {
  if (!faunaCards || !faunaCards.length) return [];

  const formattedCardsData = [];

  faunaCards.map((faunaCard: { data: CardDataProps; ref: any }) => {
    if (replaceId) {
      formattedCardsData.push({
        ...faunaCard.data,
        id: faunaCard.ref && faunaCard.ref['@ref'] && faunaCard.ref['@ref'].id
      });
    } else {
      formattedCardsData.push(faunaCard.data);
    }
  });

  return formattedCardsData;
};

export const formatUsersDataFromFauna = (
  faunaUsers: Array<{ data: SiteUser }>,
  replaceId?: boolean
): Array<SiteUser> => {
  if (!faunaUsers || !faunaUsers.length) return [];

  const formattedUsersData = [];

  faunaUsers.map((faunaUser: { data: SiteUser; ref: any }) => {
    if (replaceId) {
      formattedUsersData.push({
        ...faunaUser.data,
        id: faunaUser.ref && faunaUser.ref['@ref'] && faunaUser.ref['@ref'].id
      });
    } else {
      formattedUsersData.push(faunaUser.data);
    }
  });

  return formattedUsersData;
};

export const getCardSetOptions = (): Array<any> => {
  const mainSets = Object.values(CARD_SETS).map((cardSet: any) => {
    return {
      label: cardSet.label,
      value: cardSet.value
    };
  });

  return [
    ...mainSets,
    {
      label: CARD_SETS_JPN.VS.label,
      value: CARD_SETS_JPN.VS.value
    }
  ];
};

export const getLowercasedUsernames = (
  usernames: Array<string>
): Array<string> => {
  return usernames.map(username => username.toLowerCase());
};

export const getCardSetOptionsFromCategories = (
  categories: Array<any>
): Array<any> => {
  if (!categories || !categories.length) return [];

  const cardSetsOptions = [];

  categories.map((category: any) => {
    cardSetsOptions.push({
      label: category.title,
      value: category.handle
    });
  });

  return cardSetsOptions;
};

export const getTcgId = (
  setNumber: string,
  productCollections: Array<{ handle: string; title: string }>
): string => {
  if (!productCollections || !productCollections.length || !setNumber) return;

  let cardId: string = '';
  const cardSetValues = Object.values(CARD_SETS);
  const flattenedCardSets = cardSetValues.map(
    (cardSetValue: { label: string; value: string }) => {
      return cardSetValue.value;
    }
  );
  const foundCollection = productCollections.find((productCollection: any) => {
    return flattenedCardSets.includes(productCollection.handle);
  });

  if (foundCollection && foundCollection.handle) {
    switch (foundCollection.handle) {
      case CARD_SETS.BASE.value:
        cardId += TCG_SDK_SET_IDS.BASE;
        break;
      case CARD_SETS.JUNGLE.value:
        cardId += TCG_SDK_SET_IDS.JUNGLE;
        break;
      case CARD_SETS.FOSSIL.value:
        cardId += TCG_SDK_SET_IDS.FOSSIL;
        break;
      case CARD_SETS.TEAM_ROCKET.value:
        cardId += TCG_SDK_SET_IDS.TEAM_ROCKET;
        break;
      case CARD_SETS.BASE_2.value:
        cardId += TCG_SDK_SET_IDS.BASE_SET_2;
        break;
      case CARD_SETS.GYM_HEROES.value:
        cardId += TCG_SDK_SET_IDS.GYM_HEROES;
        break;
      case CARD_SETS.GYM_CHALLENGE.value:
        cardId += TCG_SDK_SET_IDS.GYM_CHALLENGE;
        break;
      case CARD_SETS.LEGENDARY_COLLECTION.value:
        cardId += TCG_SDK_SET_IDS.LEGENDARY_COLLECTION;
        break;
      case CARD_SETS.NEO_GENESIS.value:
        cardId += TCG_SDK_SET_IDS.NEO_GENESIS;
        break;
      case CARD_SETS.NEO_DISCOVERY.value:
        cardId += TCG_SDK_SET_IDS.NEO_DISCOVERY;
        break;
      case CARD_SETS.NEO_REVELATION.value:
        cardId += TCG_SDK_SET_IDS.NEO_REVELATION;
        break;
      case CARD_SETS.NEO_DESTINY.value:
        cardId += TCG_SDK_SET_IDS.NEO_DESTINY;
        break;
      case CARD_SETS.SOUTHERN_ISLANDS.value:
        cardId += TCG_SDK_SET_IDS.SOUTHERN_ISLANDS;
        break;
      case CARD_SETS.BLACK_STAR_PROMO.value:
        cardId += TCG_SDK_SET_IDS.BLACK_STAR_PROMOS;
        break;
      case CARD_SETS.BEST_OF_GAME.value:
        cardId += TCG_SDK_SET_IDS.BEST_OF_GAME;
        break;
      case CARD_SETS.EXPEDITION.value:
        cardId += TCG_SDK_SET_IDS.EXPEDITION;
        break;
      case CARD_SETS.AQUAPOLIS.value:
        cardId += TCG_SDK_SET_IDS.AQUAPOLIS;
        break;
      case CARD_SETS.SKYRIDGE.value:
        cardId += TCG_SDK_SET_IDS.SKYRIDGE;
        break;
    }
  }

  return `${cardId}-${setNumber}`;
};

export const getSetTotalBySetId = (setId: string): number | undefined => {
  if (!setId) return;

  const cardSetsArray = Object.values(CARD_SETS);
  const foundSet: any = cardSetsArray.find(
    (cardSet: { label: string; value: string }) => {
      return cardSet.value === setId;
    }
  );

  return foundSet && foundSet.cardCount;
};

export const getAdminBreadcrumbs = () => {
  return [
    {
      title: 'Admin Home',
      onClick: () => goToAdminHomePage()
    }
  ];
};

// generate permalink by stripping foreign characters and replacing spaces with -
export const generatePermalink = (
  productName: string,
  setNumber: string,
  setName: string,
  lang: string = 'en'
): string => {
  const productPermalink = `${productName}-${setNumber}-${setName}-${lang}`;
  return productPermalink
    .replace(/'/g, '') // remove any apostrophes
    .replace(/[^a-z0-9]+/gi, '-')
    .replace(/^-*|-*$/g, '')
    .toLowerCase();
};

// generate SKU by stripping foreign characters and replacing spaces with -
export const generateSku = (
  productName: string,
  setNumber: string,
  setName: string,
  condition?: string,
  lang: string = 'en'
): string => {
  let productSku = `${productName}-${setNumber}-${setName}-${lang}`;
  if (condition) productSku += `-${condition}`;

  return productSku
    .replace(/'/g, '') // remove any apostrophes
    .replace(/[^a-z0-9]+/gi, '-')
    .replace(/^-*|-*$/g, '')
    .toUpperCase();
};

export const getDropdownOptionsFromProductVariants = (
  variants: Array<Variant>
): Array<any> => {
  if (!variants || !variants.length) return;

  return variants.map((variant: Variant) => {
    return {
      label: `${variant.title} - ${formatPrice(variant.price)}`,
      value: variant.shopifyId
      // inventory: variant.inventoryQuantity,
      // shopifyId: variant.shopifyId,
    };
  });
};

export const getDropdownOptionsFromUsers = (
  users: Array<SiteUser>
): Array<DefaultOptionType> => {
  if (!users || !users.length) return;

  return users.map((user: SiteUser) => {
    return {
      label: user.netlifyUsername,
      value: user.netlifyUsername
    };
  });
};

export const alphabetizeFaunaUsers = (faunaUsers: Array<any>): Array<any> => {
  if (!faunaUsers || !faunaUsers.length) return;

  return faunaUsers.sort((a, b) => {
    const textA = a.data.netlifyUsername.toUpperCase();
    const textB = b.data.netlifyUsername.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const getBreadcrumbsFromProductTags = (productTags: Array<string>) => {
  if (!productTags || !productTags.length) return;

  return productTags.map((tag: string) => {
    return {
      title: tag,
      onClick: () => goToCategoryPage(tag)
    };
  });
};

export const getMetaFieldByKey = (
  fieldKey: string,
  metaFields: Array<Metafield>
): string => {
  if (!metaFields || !metaFields.length) return;

  const foundField = metaFields.find((metaField: Metafield) => {
    return metaField.key === fieldKey;
  });

  if (foundField) {
    let foundFieldValue: string;
    if (foundField.type && foundField.type === 'list.single_line_text_field') {
      foundFieldValue = JSON.parse(foundField.value);
    } else {
      foundFieldValue = foundField.value;
    }

    if (foundFieldValue && Array.isArray(foundFieldValue)) {
      return foundFieldValue[0];
    }

    return foundFieldValue;
  }
};

export const getSearchOptionsFromProducts = (
  productEdges: Array<ProductEdge>
): Array<Product> => {
  if (!productEdges || !productEdges.length) return;

  return productEdges.map((productEdge: ProductEdge) => {
    return productEdge.node;
  });
};

export const paginateCardSet = (
  data: Array<CardDataProps>,
  pageNumber = 1,
  pageSize = 10
): Array<CardDataProps> => {
  if (!data || !data.length) {
    return [];
  }

  // calculate the start and end indexes for the requested page
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = pageNumber * pageSize;

  // Slice the array based on the indexes
  return data.slice(startIndex, endIndex);
};
