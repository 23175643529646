import React, { memo, ReactElement } from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import classNames from 'classnames';
import {
  RPCAlert,
  RPCCarousel,
  RPCBreadcrumbs,
  RPCLoadingIndicator
} from '@markfazzio/react-pokemon-card';
import { useQueryParam, StringParam } from 'use-query-params';

// components
import { AppHeader } from '../AppHeader/AppHeader';
import { AppFooter } from '../AppFooter/AppFooter';
import { SiteSearch } from '../../common/SiteSearch/SiteSearch';
import { PromoCodeBar } from '../../../components/common/PromoCodeBar/PromoCodeBar';

// types
import { AppPageProps } from './AppPage.types';

// scss
import './AppPage.scss';

// hooks
import { isAdminUser } from '../../../utils/account-utils';

// common
import { SITE_CAROUSEL_ITEMS } from '../../../common/constants';

const AppPageComponent = (props: AppPageProps): ReactElement => {
  const {
    backgroundCarouselOpacity = 50,
    breadcrumbsItems,
    children,
    className,
    description,
    extraOptions,
    extraOptionsAdmin,
    extraOptionsMobile,
    headerBackgroundImageUrl,
    hideCarousel,
    hideFooter,
    hideSearch,
    isContentOverlay,
    isFlexContent,
    isLoading,
    loadingLabel,
    pageActions,
    pageBackgroundImageUrl,
    title
  } = props;

  const { isLoggedIn, isConfirmedUser, user } = useIdentityContext();
  const [queryStringConfirmedUser] = useQueryParam('confirmed', StringParam);
  const [queryStringLoggedOut] = useQueryParam('logout', StringParam);

  // TODO: get from shopify API
  const isPromoCodeActive = false;

  const pageClassNames = classNames({
    page: true,
    'has-promo-code': isPromoCodeActive,
    'is-admin-mode': user && isAdminUser && isAdminUser(user),
    [`${className}`]: className
  });

  const pageContentClassNames = classNames({
    'position-relative h-100 page__content': true,
    'd-flex': isFlexContent,
    'bg-dark-subtle': isContentOverlay
  });

  const pageStyle = pageBackgroundImageUrl
    ? { backgroundImage: `url('${pageBackgroundImageUrl}')` }
    : undefined;

  const pageMetaStyle = headerBackgroundImageUrl
    ? { backgroundImage: `url('${headerBackgroundImageUrl}')` }
    : undefined;

  const titleColClassName = pageActions ? 'col-lg-8' : 'col-12';
  const pageActionsClassName = 'page-actions text-lg-end col-lg-4';

  return (
    <>
      <div className={pageClassNames}>
        {isLoggedIn && !isConfirmedUser ? (
          <RPCAlert variant="warning" className="mb-0">
            To finish setting up your account, please{' '}
            <strong>check your email</strong> to confirm the address you
            provided.
          </RPCAlert>
        ) : undefined}
        {queryStringConfirmedUser ? (
          <RPCAlert variant="success" dismissible className="mb-0">
            Thank you for creating an account! You can now save custom cards,
            play the pull game, and view your orders!
          </RPCAlert>
        ) : undefined}
        {queryStringLoggedOut ? (
          <RPCAlert variant="success" dismissible className="mb-0">
            You have succesfully logged out. Come back again soon!
          </RPCAlert>
        ) : undefined}
        {isPromoCodeActive ? (
          <PromoCodeBar amount={15} code="WELCOMETCG24" expiration="5/31/24" />
        ) : undefined}
        <AppHeader
          extraOptions={extraOptions}
          extraOptionsMobile={extraOptionsMobile}
          extraOptionsAdmin={extraOptionsAdmin}
          hideSearch={hideSearch}
          isPromoCodeActive={isPromoCodeActive}
        />
        <div className="page__wrapper d-flex flex-column" style={pageStyle}>
          {isLoading ? (
            <RPCLoadingIndicator label={loadingLabel} full />
          ) : (
            <>
              <div className={pageContentClassNames}>
                {!hideSearch ? (
                  <section className="p-3 bg-light">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <SiteSearch
                            id="site-search"
                            placeholder="Search store for cards..."
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                ) : undefined}
                {breadcrumbsItems && breadcrumbsItems.length ? (
                  <div className="page__breadcrumbs">
                    <RPCBreadcrumbs asButtons items={breadcrumbsItems} />
                  </div>
                ) : undefined}
                {!hideCarousel && !pageBackgroundImageUrl ? (
                  <RPCCarousel
                    fullscreen
                    items={SITE_CAROUSEL_ITEMS}
                    opacity={backgroundCarouselOpacity}
                  />
                ) : undefined}
                {title || description ? (
                  <section className="page__meta" style={pageMetaStyle}>
                    <div className="text-light d-flex p-4">
                      <div className="container">
                        <div className="row">
                          <div className={titleColClassName}>
                            {title && (
                              <h1 className="page-title d-flex align-items-center">
                                {title}
                              </h1>
                            )}
                          </div>
                          {pageActions && (
                            <div className={pageActionsClassName}>
                              {pageActions}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {description && (
                              <div className="page-description">
                                {description}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : undefined}
                <>{children}</>
              </div>
            </>
          )}
        </div>
        {!hideFooter && <AppFooter />}
      </div>
    </>
  );
};

export const AppPage = memo<AppPageProps>(AppPageComponent);
