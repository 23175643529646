import React, { memo, ReactElement } from 'react';
// import {
//   RPCButton,
// } from '@markfazzio/react-pokemon-card';
import { Link } from 'gatsby';

// routing
import { ROUTES } from '../../../routing/paths';

// import {
//   INSTAGRAM_URL,
//   INSTAGRAM_URL_NFT,
//   WHATNOT_USER_URL,
// } from '../../../common/constants';

// components
import { LogoSmall } from '../../icons/LogoSmall';
import { IconDiscord } from '../../../components/icons/IconDiscord';

// constants
import { DISCORD_SERVER_URL } from '../../../common/constants';

const currentYear = new Date().getFullYear();

const AppFooterComponent = (): ReactElement => {
  return (
    <footer className="footer bg-light py-5">
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5">
          <div className="col mb-3">
            <div className="mb-3 footer__logo">
              <LogoSmall />
            </div>
            <p className="text-body-secondary mb-3">
              &copy; {currentYear} PokeTCGShop, LLC.
            </p>
            <a
              href={DISCORD_SERVER_URL}
              className="btn btn-info bg-gradient d-inline-flex align-items-center wr-nowrap"
              target="_blank"
              rel="noreferrer"
            >
              <span className="d-inline-flex me-2">
                <IconDiscord width={16} />
              </span>
              Contact Us On Discord
            </a>
          </div>

          <div className="col mb-3"></div>

          <div className="col mb-3">
            <h5 className="py-2 border-bottom">Shop</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.SHOP}
                  className="nav-link p-0 text-body-secondary"
                >
                  Shop Home
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.CART}
                  className="nav-link p-0 text-body-secondary"
                >
                  My Cart
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.USER_ORDERS}
                  className="nav-link p-0 text-body-secondary"
                >
                  My Orders
                </Link>
              </li>
            </ul>
          </div>

          <div className="col mb-3">
            <h5 className="py-2 border-bottom">Digital TCG</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.WOTC_SETS}
                  className="nav-link p-0 text-body-secondary"
                >
                  WOTC Sets
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.USER_CUSTOMS}
                  className="nav-link p-0 text-body-secondary"
                >
                  User Customs
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.PULL_GAME}
                  className="nav-link p-0 text-body-secondary"
                >
                  Pull Game
                </Link>
              </li>
            </ul>
          </div>

          <div className="col mb-3">
            <h5 className="py-2 border-bottom">My Account</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  to={ROUTES.USER_PROFILE}
                  className="nav-link p-0 text-body-secondary"
                >
                  Profile
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export const AppFooter = memo(AppFooterComponent);
