import React, {
  memo,
  useState,
  ChangeEvent,
  ReactElement,
  SyntheticEvent
} from 'react';
import {
  RPCAlert,
  RPCButton,
  RPCTextInput
} from '@markfazzio/react-pokemon-card';

// types
import { ForgotPasswordFormProps } from './ForgotPasswordForm.types';
import { IconAlert } from '../../../components/icons/IconAlert';
import {
  ACCOUNT_RECOVERY_EMAIL,
  ACCOUNT_RECOVERY_MODE
} from '../../../common/constants';

const ForgotPasswordFormComponent = (
  props: ForgotPasswordFormProps
): ReactElement => {
  const { isFormSubmitted, errorMessage, onForgotPasswordSubmit } = props;
  const [email, setEmail] = useState('');

  return (
    <>
      <p className="py-3">{<>{ACCOUNT_RECOVERY_MODE}</>}</p>
      {errorMessage ? (
        <RPCAlert icon={<IconAlert />} variant="warning">
          Error: {errorMessage}.
        </RPCAlert>
      ) : undefined}
      {!isFormSubmitted ? (
        <form
          onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
            e.preventDefault();
            onForgotPasswordSubmit(email);
          }}
        >
          <RPCTextInput
            autoFocus
            className="mb-3"
            label="Email"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value)
            }
            placeholder="Enter email"
            required
            type="email"
          />
          <RPCButton
            type="submit"
            variant="primary"
            label="Request Password Recovery Link"
          />
        </form>
      ) : (
        <RPCAlert variant="success">{ACCOUNT_RECOVERY_EMAIL}</RPCAlert>
      )}
    </>
  );
};

export const ForgotPasswordForm = memo(ForgotPasswordFormComponent);
